<template>
  <div id="subscriberscomp" class="my-5 py-5 d-flex justify-content-center align-items-center" style="box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); width: 90%;">
    <b-card no-body class="border-0 text-center">
        <div>
          <h3 class="h3">Claim</h3>
          <p>{{ claimable }}</p>
          <b-alert :show="loading && !!txHash" variant="info">
            Processing...
            <b-link :href="`https://gioexplorer.com/tx/${txHash}`" target="_blank">View on explorer</b-link>
          </b-alert>
          <b-alert :show="showSuccessMessage" variant="success">
            Successfully Claimed! <br>
            <b-link :href="`https://gioexplorer.com/tx/${txHash}`" target="_blank">View on explorer</b-link>
          </b-alert>
          <b-button @click="claimClaimable" variant="primary" :disabled="loading">
            <b-spinner v-if="loading" small type="grow"></b-spinner>
            Claim
          </b-button>
        </div>
    </b-card>
  </div>
</template>

<script setup>
import { inject, ref } from 'vue'
import { useToast } from 'vue-toastification'
import { claimProfit, getClaimableAmount } from '../../contractInterfacesNew/gio-node'
import { getAccount, waitForTransactionReceipt } from '@wagmi/core'

const toast = useToast()

const loading = ref(false)
const claimable = ref(0)
const txHash = ref('')
const showSuccessMessage = ref(false)

const wagmiConfig = inject('wagmiConfig')

const getClaimable = async () => {
  try {
    showSuccessMessage.value = false
    loading.value = true

    const account = getAccount(wagmiConfig)
    claimable.value = await getClaimableAmount(account.address)
  } catch (error) {
    toast.error(error?.message)
  } finally {
    loading.value = false
  }
}

const claimClaimable = async () => {
  try {
    showSuccessMessage.value = false
    loading.value = true
    txHash.value = await claimProfit()

    const transactionReceipt = await waitForTransactionReceipt(wagmiConfig, {
      hash: txHash.value,
    })

    if (transactionReceipt.status === 'success') {
      getClaimable()
    } else {
      throw new Error('Transaction reverted')
    }
    toast.success('Claimed!')
    showSuccessMessage.value = true
  } catch (error) {
    toast.error(error?.message)
  } finally {
    loading.value = false
  }
}

getClaimable()
</script>

<style scoped>
#subscriberscomp {
  margin: auto;
}
</style>
