<template>
  <b-container fluid class="w-100 h-100 page">
    <b-row>
      <NodeComp />
    </b-row>
    <b-row v-if="!!walletConnectAddress">
      <ClaimComp />
    </b-row>
  </b-container>
</template>

<script setup>
import { inject, ref } from 'vue';
import { watchAccount } from '@wagmi/core'
import ClaimComp from "@/components/claim/ClaimComp";
import NodeComp from "@/components/node/NodeComp";

const wagmiConfig = inject('wagmiConfig')

const walletConnectAddress = ref('')

watchAccount(wagmiConfig, {
  onChange(data) {
    if (data.address) {
      walletConnectAddress.value = data.address
    } else {
      walletConnectAddress.value = ''
    }
  },
})
</script>

<style>
.page {
  min-height: 90vh;
}
</style>
