import axios from 'axios';

export const getNodeAddress = async () => {
    try {
        const result = await axios.get('/api/address')

        return result.data
    } catch (error) {
        console.log(error.message)
    }
}