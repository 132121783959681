<template>
  <header>
    <b-navbar toggleable="lg" variant="light" class="pl-4">
      <b-navbar-brand href="#" class="ml-2">
        <img src="@/assets/logo.svg" width="20" height="22" class="d-inline-block" alt="GIO">
        <span class="logo-text">GIO</span>
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav v-if="route?.name === 'holdertokens'" class="mx-auto mr-2 w-35">
          <div class="w-100">
            <b-form-input list="input-list" id="input-with-list" placeholder="wallet address" v-model="searchAddress"></b-form-input>
            <b-form-datalist id="input-list" :options="options"></b-form-datalist>
          </div>
        </b-navbar-nav>
        <b-navbar-nav class="ml-auto mr-2">
          <router-link v-if="walletConnectAddress" :replace="false" :to="{ name: 'holdertokens', params: { address: walletConnectAddress || '0x00' }}">
            <b-button variant="outline-dark">View my tokens</b-button>
          </router-link>
        </b-navbar-nav>
        <b-navbar-nav>
          <ConnectWallet />
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </header>
</template>

<script setup>
import { inject, watch, ref } from 'vue'
import { watchAccount } from '@wagmi/core'
import { useRoute } from 'vue-router'
import { useRouter } from 'vue-router'
import * as Web3 from 'web3'
import ConnectWallet from '@/components/ConnectWallet'

const wagmiConfig = inject('wagmiConfig')

const route = useRoute()
const router = useRouter()

const walletConnectAddress = ref('')
const searchAddress = ref(route.params?.address)

const options = ref([route.params?.address])

watchAccount(wagmiConfig, {
  onChange(data) {
    if (data.address) {
      walletConnectAddress.value = data.address
    } else {
      walletConnectAddress.value = ''
    }
  },
})

watch(() => route.params,
  () => {
    searchAddress.value = route.params?.address
    options.value = [route.params?.address]
  }
)

watch(searchAddress,
  (newValue) => {
    const isValidAddress = Web3.utils.isAddress(newValue)

    if (!isValidAddress) return

    if (route.params?.address !== newValue) {
      router.push(`/holder/${newValue}`)
    }
  }
)
</script>

<style>
.logo-text {
  color: #190d40;
  font-size: 1rem;
  font-weight: 700;
  line-height: 20px;
  margin-left: 5px;
  margin-top: 1px;
}
</style>