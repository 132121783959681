<template>
  <div>
    <w3m-account-button v-if="isConnected" />
    <b-button v-else variant="primary" @click="open()">Connect Wallet</b-button>
  </div>
</template>

<script setup>
  import { inject, ref } from 'vue'
  import { useWeb3Modal } from '@web3modal/wagmi/vue';
  import { watchAccount } from '@wagmi/core'

  const { open } = useWeb3Modal();

  const wagmiConfig = inject('wagmiConfig')
  const isConnected = ref(false)

  watchAccount(wagmiConfig, {
    onChange(data) {
      if (data.status === 'connected') {
        isConnected.value = true
      } else {
        isConnected.value = false
      }
    },
  })
</script>

<style>
</style>