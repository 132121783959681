<template>
  <div id="subscriberscomp" class="my-5 py-5" style="box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); width: 90%;">
    <b-button variant="primary" class="button text-center" :class="isLoading" v-if="loading">
      Loading...
    </b-button>
    <b-card v-else no-body class="border-0 text-center">
      <h1 class="h3 mb-4">GIO Node Ownership Management</h1>
      <div class="row">
        <div class="col">
          <h5>Current Block</h5>
          <p>{{ currentBlock }}</p>
        </div>
        <div class="col">
          <h5>Transaction Fees</h5>
          <p>{{ balance }}</p>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script setup>
import { inject, ref, computed } from "vue"
import { formatEther } from 'viem'
import { useToast } from 'vue-toastification'
import { getNodeAddress } from "@/api/node"
import { getBlockNumber, getBalance } from '@wagmi/core'

const wagmiConfig = inject('wagmiConfig')
const toast = useToast()

const currentBlock = ref(0)
const balance = ref(0)
const loading = ref(false)

const getDetails = async () => {
  try {
    loading.value = true;

    const blockNumber = await getBlockNumber(wagmiConfig)
    currentBlock.value = Number(blockNumber);

    const resp = await getNodeAddress();
    const rawBalance = await getBalance(wagmiConfig, {
      address: resp?.address,
    })

    balance.value = formatEther(rawBalance?.value)
  } catch (error) {
    toast.error(error?.message)
  } finally {
    loading.value = false
  }
};

const isLoading = computed(() => ({ "is-loading": loading.value }))
getDetails()
</script>


<style scoped>
#subscriberscomp {
  margin: auto;
}

.button {
  margin: 20px auto;
}

h3 {
  margin-bottom: 10px;
}
</style>
