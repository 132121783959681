<template>
  <b-container class="w-100 h-100 d-flex justify-content-center align-items-center">
    <b-card border-variant="light" body-class="text-center" class="w-50">
      <b-card-title>Connect Wallet</b-card-title>
      <b-alert show class="note">
        <p>Connecting your wallet will allow view your nfts with possility to transfer them</p>
      </b-alert>
      <ConnectWallet />
      <router-link v-if="walletConnectAddress" :to="{ name: 'holdertokens', params: { address: walletConnectAddress || '0x00' }}">
        <b-button variant="outline-dark" class="mt-4">View my tokens</b-button>
      </router-link>
      <hr />
      Or
      <hr />
      <b-card-title>Enter Wallet Address</b-card-title>
      <b-alert show class="note">
        <p>while simply putting a wallet address only allow you view the nfts owned by the address</p>
      </b-alert>
      <b-form @submit="onSubmit">
        <b-form-group
          id="input-group"
          label="Wallet Address"
          label-for="wallet-input"
        >
          <b-input
            v-model="formAddress"
            id="wallet-input"
            type="text"
            placeholder="Enter wallet address"
            size="sm"
          ></b-input>
        </b-form-group>
        <b-button type="submit" variant="primary">Submit</b-button>
      </b-form>
    </b-card>
  </b-container>
</template>

<script setup>
import { inject, ref, onMounted } from 'vue'
import ConnectWallet from '@/components/ConnectWallet'
import { watchAccount, getAccount } from '@wagmi/core'

const emits = defineEmits(['addressInfo'])

const wagmiConfig = inject('wagmiConfig')

const account = getAccount(wagmiConfig)

const formAddress = ref('')
const walletConnectAddress = ref('')

const isWalletAutoConnect = ref(false)

const onSubmit = (event) => {
  event.preventDefault()
  emits('addressInfo', { address: formAddress.value, auto: false })
}

watchAccount(wagmiConfig, {
  onChange(data) {
    if (data.address) {
      walletConnectAddress.value = data.address
      emits('addressInfo', { address: data.address, auto: isWalletAutoConnect.value })
    } else {
      walletConnectAddress.value = ''
    }
  },
})

onMounted(() => {
  if (account?.address) {
    isWalletAutoConnect.value = true
  }
})
</script>

<style scoped lang="scss">
.note {
  padding: 0 !important;
  background-color: #f5f6fa !important;
  border: none !important;
  p {
    margin-bottom: 0;
    font-size: .8rem;
    font-style: italic;
  }
}
</style>