<template>
  <b-container class="align-middle m-auto w-100 page d-flex justify-content-center align-items-center">
    <HolderComp @addressInfo="handleAddress" />
  </b-container>
</template>

<script setup>
import { useRouter } from 'vue-router'
import HolderComp from '@/components/holder/HolderComp'

const router = useRouter()

const handleAddress = (data) => {
  if (!data.auto) {
    router.push(`/holder/${data.address}`)
  }
}
</script>

<style scoped>
</style>