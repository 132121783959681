<template>
  <b-container class="align-middle m-auto w-100 page d-flex justify-content-center align-items-center">
    <template>
      <b-row>
        <b-col  v-for="sub in 6" :key="sub" cols="3" >
          <NftItem
            :appear-clickable="appearClickable(sub)"
            :sub="sub"
            :holding="holdings[sub]"
            :img="imgMappings[sub]"
            @click="navigateToModal(sub)"
          />
        </b-col>
      </b-row>
      <b-modal v-model="showTransferModal" centered scrollable class="custom-backdrop" :title="`GIO #${selectedToken}`" @hidden="handleModalClosed">
        <b-tabs v-model="tabIndex" content-class="mt-3">
          <b-tab title="Minting Info" active>
            <b-card bg-variant="light" class="text-center">
              <b-spinner v-if="isValidating || !doneLoadingNftMeta" variant="primary"></b-spinner>
              <template v-else>
                <b-card-text v-if="!metas[0]?.minted_at">
                  <p>No minting info</p>
                </b-card-text>
                <template v-else>
                  <b-list-group>
                    <b-list-group-item v-for="meta in metas" :key="meta.transactionHash">
                      <b-card-text>
                        <p>Amount: <strong>{{ meta?.amount }}</strong></p>
                      </b-card-text>
                      <b-card-text>
                        <p>Direct GIO Amount: <strong>{{ getDirectAmount(selectedToken, meta?.minted_at) * meta?.amount }}</strong></p>
                      </b-card-text>
                      <b-card-text>
                        <p>Slot number: <b-link target="_blank" :href="`https://gioexplorer.com/tx/${meta?.transactionHash}`"><strong>{{ meta?.transactionHash?.substring(0, 8) }}</strong></b-link></p>
                      </b-card-text>
                      <b-card-text>
                        <p>Mint Date: <strong>{{ meta?.minted_at }}</strong></p>
                      </b-card-text>
                      <b-card-text>
                        <p>Server IP: <strong>{{ serverAddress }}</strong></p>
                      </b-card-text>
                    </b-list-group-item>
                  </b-list-group>
                </template>
              </template>
            </b-card>
          </b-tab>
          <b-tab title="Transfer">
            <b-card class="mb-2">
              <b-form id="transfer-form" @submit="onSubmit" @reset="onReset">
                <b-form-group id="fg-1" label="To Wallet Address" label-for="wallet-address">
                  <b-form-input
                    id="wallet-address"
                    v-model="form.toAddress"
                    placeholder=""
                    required
                  ></b-form-input>
                </b-form-group>
        
                <b-form-group id="fg-2" label="Amount To Transfer" label-for="amount">
                  <b-form-spinbutton id="amount" v-model="form.amount" min="1" :max="holdings[selectedToken]"></b-form-spinbutton>
                </b-form-group>
                <b-alert :show="transferring" variant="info">Please check your wallet to confirm transactions</b-alert>
                <b-alert :show="transferring && !!txHash" variant="info">
                  Processing...
                  <b-link :href="`https://gioexplorer.com/tx/${txHash}`" target="_blank">View on explorer</b-link>
                </b-alert>
                <b-alert :show="showSuccessMessage" variant="success">
                  Successfully Transferred <br>
                  <b-link :href="`https://gioexplorer.com/tx/${txHash}`" target="_blank">View on explorer</b-link>
                </b-alert>
                <b-alert :show="!!errorMessage" variant="danger" class="overflow-auto">
                  {{ errorMessage }} <br>
                </b-alert>
  
              </b-form>
            </b-card>
          </b-tab>
        </b-tabs>
        <template #modal-footer>
          <div v-if="tabIndex === 1" class="w-100">
            <div class="float-right">
              <b-button form="transfer-form" type="submit" variant="primary" :disabled="transferring">
                <b-spinner v-if="transferring" small type="grow"></b-spinner>
                Transfer
              </b-button>
            </div>
          </div>
        </template>
      </b-modal>
    </template>
  </b-container>
</template>
  
<script setup>
import { inject, onMounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import useSWRV from 'swrv'
import { watchAccount, getAccount, waitForTransactionReceipt } from '@wagmi/core'
import { getAccountHoldings, transferToken, getNftsMintingInfo } from '../contractInterfacesNew/gio-node'
import NftItem from '../components/holder/NftItem.vue'
import { getNodeAddress } from "@/api/node"

import nft2Img from '@/assets/GioBasic.png'
import nft5Img from '@/assets/GioBasic+.png'
import nft1Img from '@/assets/GioPremium.png'
import nft6Img from '@/assets/GioPremium+.png'
import nft3Img from '@/assets/GioMaster.png'
import nft4Img from '@/assets/GioMaster+.png'

const route = useRoute()
const router = useRouter()

const wagmiConfig = inject('wagmiConfig')

const account = ref({})

const metas = ref([])

const showTransferModal = ref(false)

const tabIndex = ref(0)

const directAmountsBase = {
  1: 15500,
  2: 1550,
  3: 155000,
  4: 630000,
  5: 6200,
  6: 62000,
}

const form = ref({
  toAddress: '',
  amount: 1,
})

const imgMappings = {
  1: nft1Img,
  2: nft2Img,
  3: nft3Img,
  4: nft4Img,
  5: nft5Img,
  6: nft6Img,
}

const holdings = ref({})
const selectedToken = ref(route?.params?.token)
const walletConnectAddress = ref('')
const transferring = ref(false)
const showSuccessMessage = ref(false)
const errorMessage = ref('')
const txHash = ref('')
const nftMetaDetails = ref({})
const doneLoadingNftMeta = ref(false)
const serverAddress = ref('')

const { data: mintDetailData, isValidating } = useSWRV(route.params?.address, getNftsMintingInfo)

const appearClickable = (tokenId) => {
  return (walletConnectAddress.value || account.value.address) && (walletConnectAddress.value || account.value.address) === route.params?.address && isTransferrable(tokenId)
}

const handleModalClosed = () => {
  router.replace(`/holder/${route.params?.address}`)
}

const isTransferrable = (tokenId) => {
  const account = getAccount(wagmiConfig)
  if (account?.address !== route.params?.address) return false
  if (holdings.value[tokenId] <= 0) return false

  return true
}

const getDirectAmount = (sub, mintAt) => {
  const switchDate = new Date('2024-03-31')
  const isBeforeSwitch = (new Date(mintAt)) < switchDate

  if (isBeforeSwitch) return directAmountsBase[sub] * 2

  return directAmountsBase[sub]
}

const navigateToModal = (tokenId) => {
  router.replace(`/holder/${route.params?.address}/${tokenId}`)
}

const showModal = (tokenId) => {
  if (!isTransferrable(tokenId)) return

  showSuccessMessage.value = false
  errorMessage.value = ''
  form.value.toAddress = ''
  form.value.amount = 1

  showTransferModal.value = true
}

const onSubmit = async (event) => {
  event.preventDefault()
  try {
    txHash.value = ''
    transferring.value = true
    showSuccessMessage.value = false
    errorMessage.value = ''

    txHash.value = await transferToken(form.value.toAddress, selectedToken.value, form.value.amount)

    const transactionReceipt = await waitForTransactionReceipt(wagmiConfig, {
      hash: txHash.value,
    })

    if (transactionReceipt.status === 'success') {
      showSuccessMessage.value = true
      getHoldings()
    } else {
      throw new Error('Transaction reverted')
    }
  } catch (error) {
    errorMessage.value = error.message
  } finally {
    transferring.value = false
  }
}

const onReset = (event) => {
  event?.preventDefault()
  form.value.toAddress = ''
  form.value.amount = 1
}

const getHoldings = async () => {
  holdings.value = await getAccountHoldings(route.params?.address)
}

const setupNftMetaDetails = async () => {
  doneLoadingNftMeta.value = false

  const resp = await getNodeAddress();


  serverAddress.value = resp?.ipAddress || '134.122.124.171'

  doneLoadingNftMeta.value = true
}

watchAccount(wagmiConfig, {
  onChange(data) {
    if (data.address) {
      walletConnectAddress.value = data.address
    } else {
      account.value = {}
      walletConnectAddress.value = ''
    }
  },
})

const setupData = async () => {
  account.value = getAccount(wagmiConfig)
  await Promise.all([
    getHoldings(),
    setupNftMetaDetails()
  ])

  if (route.params?.token) {
    if (!holdings.value[route.params?.token]) {
      router.push(`/holder/${route.params?.address}`)
      return
    }
  }
}

watch(mintDetailData, async (newValue) => {
    nftMetaDetails.value = newValue
    if (route?.params?.token) {
      metas.value = nftMetaDetails.value[route?.params?.token]
    }
    await setupNftMetaDetails()
})

if (route.params?.token) {
  showModal(route.params?.token)
}

onMounted(() => {
  setupData()
})
</script>

<style scoped>
.page {
  min-height: 90vh;
}

#__BVID__64___BV_modal_backdrop_ {
  background-color: #5c34a2;
}

.custom-backdrop .modal-backdrop {
  background-color: #5c34a2;
}
</style>